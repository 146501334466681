<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";

export default {
  data() {
    return {
      title: "Basic Action",
      items: [
        {
          text: "Email",
          href: "/",
        },
        {
          text: "Basic Action",
          active: true,
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  methods: {},
  mounted() {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div
          class="justify-content-between d-flex align-items-center mt-3 mb-4"
        >
          <h5 class="mb-0 pb-1 text-decoration-underline">Basic Action</h5>
        </div>
      </div>
      <!--end col-->
      <div class="col-12">
        <table
          class="body-wrap"
          style="
            font-family: 'Roboto', sans-serif;
            box-sizing: border-box;
            font-size: 14px;
            width: 100%;
            background-color: transparent;
            margin: 0;
          "
        >
          <tr
            style="
              font-family: 'Roboto', sans-serif;
              box-sizing: border-box;
              font-size: 14px;
              margin: 0;
            "
          >
            <td
              style="
                font-family: 'Roboto', sans-serif;
                box-sizing: border-box;
                font-size: 14px;
                vertical-align: top;
                margin: 0;
              "
              valign="top"
            ></td>
            <td
              class="container"
              width="600"
              style="
                font-family: 'Roboto', sans-serif;
                box-sizing: border-box;
                font-size: 14px;
                vertical-align: top;
                display: block !important;
                max-width: 600px !important;
                clear: both !important;
                margin: 0 auto;
              "
              valign="top"
            >
              <div
                class="content"
                style="
                  font-family: 'Roboto', sans-serif;
                  box-sizing: border-box;
                  font-size: 14px;
                  max-width: 600px;
                  display: block;
                  margin: 0 auto;
                  padding: 20px;
                "
              >
                <table
                  class="main"
                  width="100%"
                  cellpadding="0"
                  cellspacing="0"
                  itemprop="action"
                  itemscope
                  itemtype="http://schema.org/ConfirmAction"
                  style="
                    font-family: 'Roboto', sans-serif;
                    box-sizing: border-box;
                    font-size: 14px;
                    border-radius: 3px;
                    margin: 0;
                    border: none;
                  "
                >
                  <tr
                    style="
                      font-family: 'Roboto', sans-serif;
                      font-size: 14px;
                      margin: 0;
                    "
                  >
                    <td
                      class="content-wrap"
                      style="
                        font-family: 'Roboto', sans-serif;
                        box-sizing: border-box;
                        color: #495057;
                        font-size: 14px;
                        vertical-align: top;
                        margin: 0;
                        padding: 30px;
                        box-shadow: 0 3px 15px rgba(30, 32, 37, 0.06);
                        border-radius: 7px;
                        background-color: #fff;
                      "
                      valign="top"
                    >
                      <meta
                        itemprop="name"
                        content="Confirm Email"
                        style="
                          font-family: 'Roboto', sans-serif;
                          box-sizing: border-box;
                          font-size: 14px;
                          margin: 0;
                        "
                      />
                      <table
                        width="100%"
                        cellpadding="0"
                        cellspacing="0"
                        style="
                          font-family: 'Roboto', sans-serif;
                          box-sizing: border-box;
                          font-size: 14px;
                          margin: 0;
                        "
                      >
                        <tr
                          style="
                            font-family: 'Roboto', sans-serif;
                            box-sizing: border-box;
                            font-size: 14px;
                            margin: 0;
                          "
                        >
                          <td
                            class="content-block"
                            style="
                              font-family: 'Roboto', sans-serif;
                              box-sizing: border-box;
                              font-size: 14px;
                              vertical-align: top;
                              margin: 0;
                              padding: 0 0 20px;
                            "
                            valign="top"
                          >
                            <div style="margin-bottom: 15px">
                              <img
                                src="@/assets/images/logo-dark.png"
                                alt=""
                                height="23"
                              />
                            </div>
                          </td>
                        </tr>
                        <tr
                          style="
                            font-family: 'Roboto', sans-serif;
                            box-sizing: border-box;
                            font-size: 14px;
                            margin: 0;
                          "
                        >
                          <td
                            class="content-block"
                            style="
                              font-family: 'Roboto', sans-serif;
                              box-sizing: border-box;
                              font-size: 20px;
                              line-height: 1.5;
                              font-weight: 500;
                              vertical-align: top;
                              margin: 0;
                              padding: 0 0 10px;
                            "
                            valign="top"
                          >
                            Hey, Anna Adame
                          </td>
                        </tr>
                        <tr
                          style="
                            font-family: 'Roboto', sans-serif;
                            box-sizing: border-box;
                            font-size: 14px;
                            margin: 0;
                          "
                        >
                          <td
                            class="content-block"
                            style="
                              font-family: 'Roboto', sans-serif;
                              color: #878a99;
                              box-sizing: border-box;
                              line-height: 1.5;
                              font-size: 15px;
                              vertical-align: top;
                              margin: 0;
                              padding: 0 0 10px;
                            "
                            valign="top"
                          >
                            I'm writing to let you know that I can't attend
                            class for two weeks as I've had an accident and hurt
                            my back. I'm afraid I also need to ask for an
                            extension for next week's essay.
                          </td>
                        </tr>
                        <tr
                          style="
                            font-family: 'Roboto', sans-serif;
                            box-sizing: border-box;
                            font-size: 14px;
                            margin: 0;
                          "
                        >
                          <td
                            class="content-block"
                            style="
                              font-family: 'Roboto', sans-serif;
                              color: #878a99;
                              box-sizing: border-box;
                              line-height: 1.5;
                              font-size: 15px;
                              vertical-align: top;
                              margin: 0;
                              padding: 0 0 24px;
                            "
                            valign="top"
                          >
                            Until then, my friend is going to record the
                            lectures so I can listen to them at home. I will try
                            to catch up with all the reading too. However, I
                            don't think I can submit the essay next week. Is it
                            possible to have an extension?
                          </td>
                        </tr>
                        <tr
                          style="
                            font-family: 'Roboto', sans-serif;
                            box-sizing: border-box;
                            font-size: 14px;
                            margin: 0;
                          "
                        >
                          <td
                            class="content-block"
                            itemprop="handler"
                            itemscope
                            itemtype="http://schema.org/HttpActionHandler"
                            style="
                              font-family: 'Roboto', sans-serif;
                              box-sizing: border-box;
                              font-size: 14px;
                              vertical-align: top;
                              margin: 0;
                              padding: 0 0 24px;
                            "
                            valign="top"
                          >
                            <a
                              href="#"
                              itemprop="url"
                              style="
                                font-family: 'Roboto', sans-serif;
                                box-sizing: border-box;
                                font-size: 0.8125rem;
                                font-weight: 400;
                                color: #fff;
                                text-decoration: none;
                                text-align: center;
                                cursor: pointer;
                                display: inline-block;
                                border-radius: 0.25rem;
                                text-transform: capitalize;
                                background-color: #0ab39c;
                                margin: 0;
                                border-color: #0ab39c;
                                border-style: solid;
                                border-width: 1px;
                                padding: 0.5rem 0.9rem;
                              "
                              onMouseOver="this.style.background='#099885'"
                              onMouseOut="this.style.background='#0ab39c'"
                              >Read More &#8594;</a
                            >
                          </td>
                        </tr>

                        <tr
                          style="
                            font-family: 'Roboto', sans-serif;
                            box-sizing: border-box;
                            font-size: 14px;
                            margin: 0;
                            border-top: 1px solid #e9ebec;
                          "
                        >
                          <td
                            class="content-block"
                            style="
                              font-family: 'Roboto', sans-serif;
                              box-sizing: border-box;
                              font-size: 14px;
                              vertical-align: top;
                              margin: 0;
                              padding: 0;
                              padding-top: 15px;
                            "
                            valign="top"
                          >
                            <div style="display: flex; align-items: center">
                              <img
                                src="@/assets/images/users/avatar-3.jpg"
                                alt=""
                                height="35"
                                width="35"
                                style="border-radius: 50px"
                              />
                              <div style="margin-left: 8px">
                                <span style="font-weight: 600">Luis Rocha</span>
                                <p
                                  style="
                                    font-size: 13px;
                                    margin-bottom: 0px;
                                    margin-top: 3px;
                                    color: #878a99;
                                  "
                                >
                                  Creative Director
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
                <div style="text-align: center; margin: 0px auto">
                  <ul
                    style="
                      list-style: none;
                      display: flex;
                      justify-content: space-evenly;
                      padding-top: 25px;
                      padding-left: 0px;
                      margin-bottom: 20px;
                      font-family: 'Roboto', sans-serif;
                    "
                  >
                    <li>
                      <a href="#" style="color: #495057">Help Center</a>
                    </li>
                    <li>
                      <a href="#" style="color: #495057">Support 24/7</a>
                    </li>
                    <li>
                      <a href="#" style="color: #495057">Account</a>
                    </li>
                  </ul>
                  <p
                    style="
                      font-family: 'Roboto', sans-serif;
                      font-size: 14px;
                      color: #98a6ad;
                      margin: 0px;
                    "
                  >
                    2024 TANOU Academy. Design & Develop by KoueLab
                  </p>
                </div>
              </div>
            </td>
          </tr>
        </table>
        <!-- end table -->
      </div>
      <!--end col-->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-lg-12">
        <div
          class="justify-content-between d-flex align-items-center mt-3 mb-4"
        >
          <h5 class="mb-0 pb-1 text-decoration-underline">Subscribe Action</h5>
        </div>
      </div>
      <!--end col-->
      <div class="col-12">
        <table
          class="body-wrap"
          style="
            font-family: 'Roboto', sans-serif;
            box-sizing: border-box;
            font-size: 14px;
            width: 100%;
            background-color: transparent;
            margin: 0;
          "
        >
          <tr
            style="
              font-family: 'Roboto', sans-serif;
              box-sizing: border-box;
              font-size: 14px;
              margin: 0;
            "
          >
            <td
              style="
                font-family: 'Roboto', sans-serif;
                box-sizing: border-box;
                font-size: 14px;
                vertical-align: top;
                margin: 0;
              "
              valign="top"
            ></td>
            <td
              class="container"
              width="600"
              style="
                font-family: 'Roboto', sans-serif;
                box-sizing: border-box;
                font-size: 14px;
                vertical-align: top;
                display: block !important;
                max-width: 600px !important;
                clear: both !important;
                margin: 0 auto;
              "
              valign="top"
            >
              <div
                class="content"
                style="
                  font-family: 'Roboto', sans-serif;
                  box-sizing: border-box;
                  font-size: 14px;
                  max-width: 600px;
                  display: block;
                  margin: 0 auto;
                  padding: 20px;
                "
              >
                <table
                  class="main"
                  width="100%"
                  cellpadding="0"
                  cellspacing="0"
                  itemprop="action"
                  itemscope
                  itemtype="http://schema.org/ConfirmAction"
                  style="
                    font-family: 'Roboto', sans-serif;
                    box-sizing: border-box;
                    font-size: 14px;
                    border-radius: 3px;
                    margin: 0;
                    border: none;
                  "
                >
                  <tr
                    style="
                      font-family: 'Roboto', sans-serif;
                      font-size: 14px;
                      margin: 0;
                    "
                  >
                    <td
                      class="content-wrap"
                      style="
                        font-family: 'Roboto', sans-serif;
                        box-sizing: border-box;
                        color: #495057;
                        font-size: 14px;
                        vertical-align: top;
                        margin: 0;
                        padding: 30px;
                        box-shadow: 0 3px 15px rgba(30, 32, 37, 0.06);
                        border-radius: 7px;
                        background-color: #fff;
                      "
                      valign="top"
                    >
                      <meta
                        itemprop="name"
                        content="Confirm Email"
                        style="
                          font-family: 'Roboto', sans-serif;
                          box-sizing: border-box;
                          font-size: 14px;
                          margin: 0;
                        "
                      />
                      <table
                        width="100%"
                        cellpadding="0"
                        cellspacing="0"
                        style="
                          font-family: 'Roboto', sans-serif;
                          box-sizing: border-box;
                          font-size: 14px;
                          margin: 0;
                        "
                      >
                        <tr
                          style="
                            font-family: 'Roboto', sans-serif;
                            box-sizing: border-box;
                            font-size: 14px;
                            margin: 0;
                          "
                        >
                          <td
                            class="content-block"
                            style="
                              font-family: 'Roboto', sans-serif;
                              box-sizing: border-box;
                              font-size: 14px;
                              vertical-align: top;
                              margin: 0;
                              padding: 0 0 20px;
                            "
                            valign="top"
                          >
                            <div
                              style="text-align: center; margin-bottom: 15px"
                            >
                              <img
                                src="@/assets/images/logo-dark.png"
                                alt=""
                                height="23"
                              />
                            </div>
                          </td>
                        </tr>
                        <tr
                          style="
                            font-family: 'Roboto', sans-serif;
                            box-sizing: border-box;
                            font-size: 14px;
                            margin: 0;
                          "
                        >
                          <td
                            class="content-block"
                            style="
                              font-family: 'Roboto', sans-serif;
                              box-sizing: border-box;
                              line-height: 1.5;
                              font-size: 24px;
                              vertical-align: top;
                              margin: 0;
                              padding: 0 0 10px;
                              text-align: center;
                              font-weight: 500;
                            "
                            valign="top"
                          >
                            Please confirm subscription
                          </td>
                        </tr>
                        <tr
                          style="
                            font-family: 'Roboto', sans-serif;
                            box-sizing: border-box;
                            font-size: 14px;
                            margin: 0;
                          "
                        >
                          <td
                            class="content-block"
                            style="
                              font-family: 'Roboto', sans-serif;
                              color: #878a99;
                              line-height: 1.5;
                              box-sizing: border-box;
                              font-size: 15px;
                              vertical-align: top;
                              margin: 0;
                              padding: 0 0 24px;
                              text-align: center;
                            "
                            valign="top"
                          >
                            Since yesterday, I've been receiving thousands of
                            emails, asking me to confirm the subscription.
                          </td>
                        </tr>
                        <tr
                          style="
                            font-family: 'Roboto', sans-serif;
                            box-sizing: border-box;
                            font-size: 14px;
                            margin: 0;
                          "
                        >
                          <td
                            class="content-block"
                            itemprop="handler"
                            itemscope
                            itemtype="http://schema.org/HttpActionHandler"
                            style="
                              font-family: 'Roboto', sans-serif;
                              box-sizing: border-box;
                              font-size: 14px;
                              vertical-align: top;
                              margin: 0;
                              padding: 0 0 24px;
                              text-align: center;
                            "
                            valign="top"
                          >
                            <a
                              href="#"
                              itemprop="url"
                              style="
                                font-family: 'Roboto', sans-serif;
                                box-sizing: border-box;
                                font-size: 0.8125rem;
                                font-weight: 400;
                                color: #fff;
                                text-decoration: none;
                                text-align: center;
                                cursor: pointer;
                                display: inline-block;
                                border-radius: 0.25rem;
                                text-transform: capitalize;
                                background-color: #0ab39c;
                                margin: 0;
                                border-color: #0ab39c;
                                border-style: solid;
                                border-width: 1px;
                                padding: 0.5rem 0.9rem;
                              "
                              onMouseOver="this.style.background='#099885'"
                              onMouseOut="this.style.background='#0ab39c'"
                              >Yes, subscribe me</a
                            >
                          </td>
                        </tr>

                        <tr
                          style="
                            font-family: 'Roboto', sans-serif;
                            box-sizing: border-box;
                            font-size: 14px;
                            margin: 0;
                            border-top: 1px solid #e9ebec;
                          "
                        >
                          <td
                            class="content-block"
                            style="
                              color: #878a99;
                              text-align: center;
                              font-family: 'Roboto', sans-serif;
                              box-sizing: border-box;
                              font-size: 14px;
                              vertical-align: top;
                              margin: 0;
                              padding: 0;
                              padding-top: 15px;
                            "
                            valign="top"
                          >
                            If you received this email by mistake, simply delete
                            it. You won't be subscribed if you don't click the
                            confirmation link above.
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
                <div style="text-align: center; margin: 0px auto">
                  <ul
                    style="
                      list-style: none;
                      display: flex;
                      justify-content: space-evenly;
                      padding-top: 25px;
                      margin-bottom: 20px;
                      padding-left: 0px;
                      font-family: 'Roboto', sans-serif;
                    "
                  >
                    <li>
                      <a href="#" style="color: #495057">Help Center</a>
                    </li>
                    <li>
                      <a href="#" style="color: #495057">Support 24/7</a>
                    </li>
                    <li>
                      <a href="#" style="color: #495057">Account</a>
                    </li>
                  </ul>
                  <p
                    style="
                      font-family: 'Roboto', sans-serif;
                      font-size: 14px;
                      color: #98a6ad;
                      margin: 0px;
                    "
                  >
                    2024 TANOU Academy. Design & Develop by KoueLab
                  </p>
                </div>
              </div>
            </td>
          </tr>
        </table>
        <!-- end table -->
      </div>
      <!--end col-->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-lg-12">
        <div
          class="justify-content-between d-flex align-items-center mt-3 mb-4"
        >
          <h5 class="mb-0 pb-1 text-decoration-underline">
            Email Verify Action
          </h5>
        </div>
      </div>
      <!--end col-->
      <div class="col-12">
        <table
          class="body-wrap"
          style="
            font-family: 'Roboto', sans-serif;
            box-sizing: border-box;
            font-size: 14px;
            width: 100%;
            background-color: transparent;
            margin: 0;
          "
        >
          <tr
            style="
              font-family: 'Roboto', sans-serif;
              box-sizing: border-box;
              font-size: 14px;
              margin: 0;
            "
          >
            <td
              style="
                font-family: 'Roboto', sans-serif;
                box-sizing: border-box;
                font-size: 14px;
                vertical-align: top;
                margin: 0;
              "
              valign="top"
            ></td>
            <td
              class="container"
              width="600"
              style="
                font-family: 'Roboto', sans-serif;
                box-sizing: border-box;
                font-size: 14px;
                vertical-align: top;
                display: block !important;
                max-width: 600px !important;
                clear: both !important;
                margin: 0 auto;
              "
              valign="top"
            >
              <div
                class="content"
                style="
                  font-family: 'Roboto', sans-serif;
                  box-sizing: border-box;
                  font-size: 14px;
                  max-width: 600px;
                  display: block;
                  margin: 0 auto;
                  padding: 20px;
                "
              >
                <table
                  class="main"
                  width="100%"
                  cellpadding="0"
                  cellspacing="0"
                  itemprop="action"
                  itemscope
                  itemtype="http://schema.org/ConfirmAction"
                  style="
                    font-family: 'Roboto', sans-serif;
                    box-sizing: border-box;
                    font-size: 14px;
                    border-radius: 3px;
                    margin: 0;
                    border: none;
                  "
                >
                  <tr
                    style="
                      font-family: 'Roboto', sans-serif;
                      font-size: 14px;
                      margin: 0;
                    "
                  >
                    <td
                      class="content-wrap"
                      style="
                        font-family: 'Roboto', sans-serif;
                        box-sizing: border-box;
                        color: #495057;
                        font-size: 14px;
                        vertical-align: top;
                        margin: 0;
                        padding: 30px;
                        box-shadow: 0 3px 15px rgba(30, 32, 37, 0.06);
                        border-radius: 7px;
                        background-color: #fff;
                      "
                      valign="top"
                    >
                      <meta
                        itemprop="name"
                        content="Confirm Email"
                        style="
                          font-family: 'Roboto', sans-serif;
                          box-sizing: border-box;
                          font-size: 14px;
                          margin: 0;
                        "
                      />
                      <table
                        width="100%"
                        cellpadding="0"
                        cellspacing="0"
                        style="
                          font-family: 'Roboto', sans-serif;
                          box-sizing: border-box;
                          font-size: 14px;
                          margin: 0;
                        "
                      >
                        <tr
                          style="
                            font-family: 'Roboto', sans-serif;
                            box-sizing: border-box;
                            font-size: 14px;
                            margin: 0;
                          "
                        >
                          <td
                            class="content-block"
                            style="
                              font-family: 'Roboto', sans-serif;
                              box-sizing: border-box;
                              font-size: 14px;
                              vertical-align: top;
                              margin: 0;
                              padding: 0 0 20px;
                            "
                            valign="top"
                          >
                            <div
                              style="text-align: center; margin-bottom: 15px"
                            >
                              <img
                                src="@/assets/images/logo-dark.png"
                                alt=""
                                height="23"
                              />
                            </div>
                          </td>
                        </tr>
                        <tr
                          style="
                            font-family: 'Roboto', sans-serif;
                            box-sizing: border-box;
                            font-size: 14px;
                            margin: 0;
                          "
                        >
                          <td
                            class="content-block"
                            style="
                              font-family: 'Roboto', sans-serif;
                              box-sizing: border-box;
                              font-size: 24px;
                              vertical-align: top;
                              margin: 0;
                              padding: 0 0 10px;
                              text-align: center;
                            "
                            valign="top"
                          >
                            <h4
                              style="
                                font-family: 'Roboto', sans-serif;
                                font-weight: 500;
                              "
                            >
                              Please Verify your email
                            </h4>
                          </td>
                        </tr>
                        <tr
                          style="
                            font-family: 'Roboto', sans-serif;
                            box-sizing: border-box;
                            font-size: 14px;
                            margin: 0;
                          "
                        >
                          <td
                            class="content-block"
                            style="
                              font-family: 'Roboto', sans-serif;
                              color: #878a99;
                              box-sizing: border-box;
                              font-size: 15px;
                              vertical-align: top;
                              margin: 0;
                              padding: 0 0 26px;
                              text-align: center;
                            "
                            valign="top"
                          >
                            Yes, we know
                            <p style="margin-bottom: 13px">
                              An email to verify an email.
                            </p>
                            <p style="margin-bottom: 0">
                              Please validate your email address in order to get
                              started using product.
                            </p>
                          </td>
                        </tr>
                        <tr
                          style="
                            font-family: 'Roboto', sans-serif;
                            box-sizing: border-box;
                            font-size: 14px;
                            margin: 0;
                          "
                        >
                          <td
                            class="content-block"
                            itemprop="handler"
                            itemscope
                            itemtype="http://schema.org/HttpActionHandler"
                            style="
                              font-family: 'Roboto', sans-serif;
                              box-sizing: border-box;
                              font-size: 14px;
                              vertical-align: top;
                              margin: 0;
                              padding: 0 0 22px;
                              text-align: center;
                            "
                            valign="top"
                          >
                            <a
                              href="#"
                              itemprop="url"
                              style="
                                font-family: 'Roboto', sans-serif;
                                box-sizing: border-box;
                                font-size: 0.8125rem;
                                color: #fff;
                                text-decoration: none;
                                font-weight: 400;
                                text-align: center;
                                cursor: pointer;
                                display: inline-block;
                                border-radius: 0.25rem;
                                text-transform: capitalize;
                                background-color: #405189;
                                margin: 0;
                                border-color: #405189;
                                border-style: solid;
                                border-width: 1px;
                                padding: 0.5rem 0.9rem;
                              "
                              >Verify Your Email</a
                            >
                          </td>
                        </tr>
                        <tr
                          style="
                            font-family: 'Roboto', sans-serif;
                            box-sizing: border-box;
                            font-size: 14px;
                            margin: 0;
                          "
                        >
                          <td
                            class="content-block"
                            style="
                              color: #878a99;
                              text-align: center;
                              font-family: 'Roboto', sans-serif;
                              box-sizing: border-box;
                              font-size: 14px;
                              vertical-align: top;
                              margin: 0;
                              padding: 0;
                              padding-top: 5px;
                            "
                            valign="top"
                          >
                            <p style="margin-bottom: 10px">
                              Or verify using this link:
                            </p>
                            <a
                              href="https://themesbrand.com/velzon/"
                              target="_blank"
                              >https://themesbrand.com/velzon/</a
                            >
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
                <div
                  style="
                    text-align: center;
                    margin: 25px auto 0px auto;
                    font-family: 'Roboto', sans-serif;
                  "
                >
                  <h4
                    style="
                      font-weight: 500;
                      line-height: 1.5;
                      font-family: 'Roboto', sans-serif;
                    "
                  >
                    Need Help ?
                  </h4>
                  <p style="color: #878a99; line-height: 1.5">
                    Please send and feedback or bug info to
                    <a href="" style="font-weight: 500">info@velzon.com</a>
                  </p>
                  <p
                    style="
                      font-family: 'Roboto', sans-serif;
                      font-size: 14px;
                      color: #98a6ad;
                      margin: 0px;
                    "
                  >
                    2024 TANOU Academy. Design & Develop by KoueLab
                  </p>
                </div>
              </div>
            </td>
          </tr>
        </table>
        <!-- end table -->
      </div>
      <!--end col-->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-lg-12">
        <div
          class="justify-content-between d-flex align-items-center mt-3 mb-4"
        >
          <h5 class="mb-0 pb-1 text-decoration-underline">
            Password Changes Action
          </h5>
        </div>
      </div>
      <!--end col-->
      <div class="col-12">
        <table
          class="body-wrap"
          style="
            font-family: 'Roboto', sans-serif;
            box-sizing: border-box;
            font-size: 14px;
            width: 100%;
            background-color: transparent;
            margin: 0;
          "
        >
          <tr
            style="
              font-family: 'Roboto', sans-serif;
              box-sizing: border-box;
              font-size: 14px;
              margin: 0;
            "
          >
            <td
              style="
                font-family: 'Roboto', sans-serif;
                box-sizing: border-box;
                font-size: 14px;
                vertical-align: top;
                margin: 0;
              "
              valign="top"
            ></td>
            <td
              class="container"
              width="600"
              style="
                font-family: 'Roboto', sans-serif;
                box-sizing: border-box;
                font-size: 14px;
                vertical-align: top;
                display: block !important;
                max-width: 600px !important;
                clear: both !important;
                margin: 0 auto;
              "
              valign="top"
            >
              <div
                class="content"
                style="
                  font-family: 'Roboto', sans-serif;
                  box-sizing: border-box;
                  font-size: 14px;
                  max-width: 600px;
                  display: block;
                  margin: 0 auto;
                  padding: 20px;
                "
              >
                <table
                  class="main"
                  width="100%"
                  cellpadding="0"
                  cellspacing="0"
                  itemprop="action"
                  itemscope
                  itemtype="http://schema.org/ConfirmAction"
                  style="
                    font-family: 'Roboto', sans-serif;
                    box-sizing: border-box;
                    font-size: 14px;
                    border-radius: 3px;
                    margin: 0;
                    border: none;
                  "
                >
                  <tr
                    style="
                      font-family: 'Roboto', sans-serif;
                      font-size: 14px;
                      margin: 0;
                    "
                  >
                    <td
                      class="content-wrap"
                      style="
                        font-family: 'Roboto', sans-serif;
                        box-sizing: border-box;
                        color: #495057;
                        font-size: 14px;
                        vertical-align: top;
                        margin: 0;
                        padding: 30px;
                        box-shadow: 0 3px 15px rgba(30, 32, 37, 0.06);
                        border-radius: 7px;
                        background-color: #fff;
                      "
                      valign="top"
                    >
                      <meta
                        itemprop="name"
                        content="Confirm Email"
                        style="
                          font-family: 'Roboto', sans-serif;
                          box-sizing: border-box;
                          font-size: 14px;
                          margin: 0;
                        "
                      />
                      <table
                        width="100%"
                        cellpadding="0"
                        cellspacing="0"
                        style="
                          font-family: 'Roboto', sans-serif;
                          box-sizing: border-box;
                          font-size: 14px;
                          margin: 0;
                        "
                      >
                        <tr
                          style="
                            font-family: 'Roboto', sans-serif;
                            box-sizing: border-box;
                            font-size: 14px;
                            margin: 0;
                          "
                        >
                          <td
                            class="content-block"
                            style="
                              font-family: 'Roboto', sans-serif;
                              box-sizing: border-box;
                              font-size: 14px;
                              vertical-align: top;
                              margin: 0;
                              padding: 0 0 20px;
                            "
                            valign="top"
                          >
                            <div style="text-align: center">
                              <i
                                data-feather="lock"
                                style="
                                  color: #0ab39c;
                                  fill: rgba(10, 179, 156, 0.16);
                                  height: 30px;
                                  width: 30px;
                                "
                              ></i>
                            </div>
                          </td>
                        </tr>
                        <tr
                          style="
                            font-family: 'Roboto', sans-serif;
                            box-sizing: border-box;
                            font-size: 14px;
                            margin: 0;
                          "
                        >
                          <td
                            class="content-block"
                            style="
                              font-family: 'Roboto', sans-serif;
                              box-sizing: border-box;
                              font-size: 24px;
                              vertical-align: top;
                              margin: 0;
                              padding: 0 0 10px;
                              text-align: center;
                            "
                            valign="top"
                          >
                            <h4
                              style="
                                font-family: 'Roboto', sans-serif;
                                margin-bottom: 0px;
                                font-weight: 500;
                                line-height: 1.5;
                              "
                            >
                              Change or reset your password
                            </h4>
                          </td>
                        </tr>
                        <tr
                          style="
                            font-family: 'Roboto', sans-serif;
                            box-sizing: border-box;
                            font-size: 14px;
                            margin: 0;
                          "
                        >
                          <td
                            class="content-block"
                            style="
                              font-family: 'Roboto', sans-serif;
                              color: #878a99;
                              box-sizing: border-box;
                              font-size: 15px;
                              vertical-align: top;
                              margin: 0;
                              padding: 0 0 12px;
                              text-align: center;
                            "
                            valign="top"
                          >
                            <p style="margin-bottom: 13px; line-height: 1.5">
                              You can change your password for security reasons
                              or reset it if you forget it. Your Google Account
                              password is used to access many Google products,
                              like Gmail and YouTube.
                            </p>
                          </td>
                        </tr>
                        <tr
                          style="
                            font-family: 'Roboto', sans-serif;
                            box-sizing: border-box;
                            font-size: 14px;
                            margin: 0;
                          "
                        >
                          <td
                            class="content-block"
                            itemprop="handler"
                            itemscope
                            itemtype="http://schema.org/HttpActionHandler"
                            style="
                              font-family: 'Roboto', sans-serif;
                              box-sizing: border-box;
                              font-size: 14px;
                              vertical-align: top;
                              margin: 0;
                              padding: 0 0 22px;
                              text-align: center;
                            "
                            valign="top"
                          >
                            <a
                              href="#"
                              itemprop="url"
                              style="
                                font-family: 'Roboto', sans-serif;
                                box-sizing: border-box;
                                font-size: 0.8125rem;
                                color: #fff;
                                text-decoration: none;
                                font-weight: 400;
                                text-align: center;
                                cursor: pointer;
                                display: inline-block;
                                border-radius: 0.25rem;
                                text-transform: capitalize;
                                background-color: #405189;
                                margin: 0;
                                border-color: #405189;
                                border-style: solid;
                                border-width: 1px;
                                padding: 0.5rem 0.9rem;
                              "
                              >Reset Password</a
                            >
                          </td>
                        </tr>

                        <tr
                          style="
                            font-family: 'Roboto', sans-serif;
                            box-sizing: border-box;
                            font-size: 14px;
                            margin: 0;
                          "
                        >
                          <td
                            class="content-block"
                            style="
                              color: #878a99;
                              font-family: 'Roboto', sans-serif;
                              box-sizing: border-box;
                              font-size: 14px;
                              vertical-align: top;
                              margin: 0;
                              padding: 0;
                              padding-top: 5px;
                            "
                            valign="top"
                          >
                            <h5
                              style="
                                font-family: 'Roboto', sans-serif;
                                margin-bottom: 5px;
                                font-weight: 500;
                                line-height: 1.5;
                                font-size: 15px;
                              "
                            >
                              Change your password
                            </h5>
                            <ul
                              style="
                                margin-bottom: 0px;
                                gap: 0.5rem;
                                flex: 1 1 auto;
                                display: flex;
                                flex-direction: column;
                                align-self: stretch;
                              "
                            >
                              <li>
                                Open your account. You might need to sign in.
                              </li>
                              <li>
                                Under "Security," select Signing in to Google.
                              </li>
                              <li>
                                Choose Password. You might need to sign in
                                again.
                              </li>
                              <li>
                                Enter your new password, then select Change
                                Password.
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
                <div style="text-align: center; margin: 28px auto 0px auto">
                  <h4>Need Help ?</h4>
                  <p style="color: #878a99">
                    Please send and feedback or bug info to
                    <a href="" style="font-weight: 500px">info@velzon.com</a>
                  </p>
                  <p
                    style="
                      font-family: 'Roboto', sans-serif;
                      font-size: 14px;
                      color: #98a6ad;
                      margin: 0px;
                    "
                  >
                    2024 TANOU Academy. Design & Develop by KoueLab
                  </p>
                </div>
              </div>
            </td>
          </tr>
        </table>
        <!-- end table -->
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
